import React from 'react'
import { Component } from 'react'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import { toast } from 'react-toastify'
import Spinner from 'react-bootstrap/Spinner'
import { Link } from 'react-router-dom'
import FloatingLabel from 'react-bootstrap/FloatingLabel'

class Inicio extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse( localStorage.getItem('dym_user') ),
            tipovisualizacion: 'taza',
            loadingTaza: true,
            taza: false,
            cambiar: '',
            nombre_titular: '',
            apellido_titular: '',
            cedula_titular: '',
            apodo: '',
            numcuenta: '',
            banco: '',
            is_pagomovil: 'no',
            tipo_cuenta: '',
            datos_bancarios_permitir: false,
            reglasbanco: [],
            tipo_cedula: 'V',
            loadingVerificacion: false,
            imagenlista: false,
            loadingImagen: false,
            imagen: false,
            cuentas: [],
            taza_usd: false,
            monto_usd: 0,
            ws_number: 'https://wa.me/56949636168?text=Hola%20tengo%20una%20consulta%20sobre%20DYM%20Solutions',
            minimo_cambiar: 15000,
            maximo_cambiar: 100000,
        }

        this.handleChangeState = this.handleChangeState.bind(this)
        this.handleChangeMonto = this.handleChangeMonto.bind(this)
        this.handleChangeSelectBanco = this.handleChangeSelectBanco.bind(this)
        this.handleChangeSelectTipoCuenta = this.handleChangeSelectTipoCuenta.bind(this)
        this.handleChangeFile = this.handleChangeFile.bind(this)
        this.handleChangeCuenta = this.handleChangeCuenta.bind(this)
        this.handleChangeMontoV2 = this.handleChangeMontoV2.bind(this)
    }

    handleChangeCuenta(e){
        const { value } = e.target
        const { cuentas } = this.state

        const i = cuentas.findIndex(cu => cu._id['$oid'] === value)

        if(i > -1){
            const cuenta = cuentas[i]
            return this.setState({
                nombre_titular: cuenta.nombre_titular,
                apellido_titular: cuenta.apellido_titular,
                cedula_titular: cuenta.cedula_titular,
                apodo: cuenta.apodo,
                numcuenta: cuenta.numcuenta,
                banco: cuenta.banco,
                is_pagomovil: cuenta.is_pagomovil === 'si' ? 'si' : 'no',
                tipo_cuenta: cuenta.tipo_cuenta,
                tipo_cedula: cuenta.tipo_cedula,
                tipovisualizacion: 'resumen',
                datos_bancarios_permitir: true
            })

        }

    }

    handleChangeFile(e){

        if(e.target.files.length < 1) return toast.error('Sin archivos encontrados')

        const formato = e.target.files[0].type
        
        // if(formato !== 'image/png') return toast.error('Formato inválido, carga solo imágenes jpg')

        const data = new FormData() 
        data.append('imagen', e.target.files[0])
        this.setState({ loadingImagen: true })
        return fetch('https://aquicambias.com/server/dymsolutionsserver/public/archivo',{
            method: 'POST',
            body: data
        })
        .then(res => res.json())
        .then(res => {
            
            if(res.link){
                toast.success('Imagen cargada exitosamente')
                this.setState({ imagen: res.link, loadingImagen: false, imagenlista: true })
            }

            return this.setState({ loadingImagen: false })
        })
        .catch(error => {
            console.log(error)
            this.setState({ loadingImagen: false })
            return toast.error('No se pudo subir la imagen')
        })


    }

    showAcciones(){
        const { user } = this.state

        if(!user) return false

        return <Row style={{ marginTop: -75, marginBottom: 24, textAlign: 'right' }}>
            <Col xs={6}></Col>
            <Col xs={6}>
            <p className="mb-0">{user.nombre} {user.apellido}</p>
            <Link style={{ textDecoration: 'none' }}  to="/login"><Button style={{ fontSize: 10 }} size="sm" variant="outline-danger">SALIR</Button></Link>
            </Col>
        </Row>

    }

    handleChangeMontoV2(e){
        const { value } = e.target
        const { taza_usd, taza } = this.state

        let valor = value.replace(/\D/g, "")
        valor = parseFloat(valor)

        const VES = valor * taza_usd.ammount
        const CLP = VES / taza.ammount
        const monto_clp = isNaN(CLP) !== true ? CLP : 0

        return this.setState({ cambiar: monto_clp, monto_usd: valor ? valor : 0 })
    }

    handleChangeMonto(e){
        const { name, value } = e.target
        const { taza_usd, taza } = this.state

        const valor = value.replace(/\D/g, "")

        const total = (valor*taza.ammount) / taza_usd.ammount

        return this.setState({ [name]: valor, monto_usd: total })
    }

    handleChangeSelectBanco(e){
        this.setState({ banco: e.value })
        const { numcuenta } = this.state
        return this.validarCuenta(numcuenta,e.value)
    }

    handleChangeSelectTipoCuenta(e){
        this.setState({ tipo_cuenta: e.value })
        const { numcuenta, banco } = this.state
        return this.validarCuenta(numcuenta,banco)
    }

    validarCuenta(cuenta,banco){
        const { reglasbanco, is_pagomovil } = this.state

        if(!banco) return false

        if(is_pagomovil==='si'){
            if(cuenta.length === 11 ){
                return this.setState({ datos_bancarios_permitir: true })
            } else {
                return this.setState({ datos_bancarios_permitir: false })
            }
        }

        const buscar = reglasbanco.findIndex(regla => regla.slug === banco)

        if(buscar > -1){

            const banco_elegido = reglasbanco[buscar]
            const primeros_digitos = cuenta.substr(0,4)
            if(banco_elegido.iniciales){

                if(primeros_digitos.toString() === banco_elegido.iniciales && cuenta.length === banco_elegido.longitud ){
                    this.setState({ datos_bancarios_permitir: true })
                } else {
                    this.setState({ datos_bancarios_permitir: false })
                }

            } else {

                if(cuenta.length === banco_elegido.longitud ){
                    this.setState({ datos_bancarios_permitir: true })
                } else {
                    this.setState({ datos_bancarios_permitir: false })
                }
            }
                

        }

    }
            
    handleChangeState(e){
        const { name, value } = e.target
        const { banco, numcuenta } = this.state
        if(name === 'numcuenta') this.validarCuenta(value,banco)
        if(name === 'banco') this.validarCuenta(numcuenta,value)
        if(name==='is_pagomovil'){
            if(value === 'si'){
                this.setState({ tipo_cuenta: 'pagomovil', numcuenta: '' })
                this.validarCuenta('',banco)
            }
        }
        if(name==='cedula_titular') {
            let valor = value.replace(/\D/g, "")
            valor = parseFloat(valor)
            return this.setState({ cedula_titular: valor ? valor : 0 })
        } else if(name==='numcuenta'){
            let cuenta = value.replace(/\D/g, "")
            return this.setState({ numcuenta: cuenta })
        }

        return this.setState({ [name]: value })
    }

    showByTipoVisualizacion(){
        const { tipovisualizacion } = this.state

        if(tipovisualizacion==='titular') return this.showTitularForm()
        if(tipovisualizacion==='datosbancarios') return this.showDatosBancariosForm()
        if(tipovisualizacion==='taza') return this.showTazaForm()
        if(tipovisualizacion==='resumen') return this.showResumen()
        if(tipovisualizacion==='confirmacion') return this.showConfirmacion()
        if(tipovisualizacion === 'terminado') return this.terminado()
        if(tipovisualizacion === 'cerrado') return this.AppCerrada()
    }

    AppCerrada(){
        return <div className="text-center" style={{ padding: 40 }}>
            <img alt="cerrado" style={{ width: 80 }} src="clock.gif" />
            <h2 className="mt-3"><b>¡Vuelve pronto!</b></h2>
            <h3>En este momento estamos cerrados</h3>
        </div>
    }

    componentDidMount(){
        const { user } = this.state
        this.getCustomConfiguracion('montos')
        this.getTaza()
        this.getBancos()
        this.getTazaCustom('VES','USD')
        this.getConfiguracion()
        if(user.login === 'user') this.getCuentas(user.email)

        setInterval(async () => {
            await this.getTazaV3()
        }, 10000);

        setInterval(async () => {
            this.getConfiguracion()
            this.getTazaCustom('VES','USD')
        }, 60000);
    }

    getCuentas(email){
        return fetch(`https://us-east-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/webdym-xbzid/service/webdym/incoming_webhook/getCuentas?email=${email}`)
        .then(res => res.json())
        .then(cuentas => {
            console.log(cuentas)
            return this.setState({ cuentas })
        })
        .catch(error => {
            toast.error('Error al consultar la información')
            return false
        })
    }

    getCustomConfiguracion(tipo){
        return fetch(`https://us-east-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/webdym-xbzid/service/webdym/incoming_webhook/getSettingsByTipo?tipo=${tipo}`)
        .then(res => res.json())
        .then(res => {
            const datos = JSON.parse(res)
            return this.setState({ minimo_cambiar: datos.minimo, maximo_cambiar: datos.maximo })
        })
        .catch(error => {
            return false
        })
    }

    getConfiguracion(){
        return fetch('https://us-east-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/webdym-xbzid/service/webdym/incoming_webhook/getConfiguracion')
        .then(res => res.json())
        .then(res => {
            if(res.valor !== "1") return this.setState({ tipovisualizacion: 'cerrado' })
        })
        .catch(error => {
            return false
        })
    }

    getBancos(){
        return fetch('https://us-east-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/webdym-xbzid/service/webdym/incoming_webhook/bancos')
        .then(res => res.json())
        .then(res => {
            const reglasbanco = JSON.parse(res)
            return this.setState({ reglasbanco })
        })
        .catch(error => {
            toast.error('Error al consultar la información')
            return false
        })
    }

    getTazaV3(){
        const { taza } = this.state
        return fetch('https://us-east-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/webdym-xbzid/service/webdym/incoming_webhook/getTaza')
        .then(res => res.json())
        .then(res => {
            const taza_nueva = JSON.parse(res)

            if(taza.ammount !== taza_nueva.ammount) {
                toast.info(`La taza cambió de ${taza.ammount} a ${taza_nueva.ammount}, verifica el nuevo monto y confirma tu transacción`)
            }
            return this.setState({ taza: taza_nueva })
        })
        .catch(error => {
            toast.error('Error al validar la taza, intenta de nuevo')
            return false
        })
    }

    getTazaV2(){
        const { taza } = this.state
        this.setState({  loadingVerificacion: true })
        let permitir = true
        return fetch('https://us-east-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/webdym-xbzid/service/webdym/incoming_webhook/getTaza')
        .then(res => res.json())
        .then(res => {
            const taza_nueva = JSON.parse(res)

            if(taza.ammount !== taza_nueva.ammount) {
                toast.info(`La taza cambió de ${taza.ammount} a ${taza_nueva.ammount}, verifica el nuevo monto y confirma tu transacción`)
                permitir = false
            }
            this.setState({ loadingVerificacion: false, taza: taza_nueva })
            return permitir
        })
        .catch(error => {
            this.setState({ loadingVerificacion: false })
            toast.error('Error al validar la taza, intenta de nuevo')
            return false
        })
    }

    getTazaCustom(from,to){
        return fetch(`https://us-east-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/webdym-xbzid/service/webdym/incoming_webhook/getTazaCustom?from=${from}&to=${to}`)
        .then(res => res.json())
        .then(res => {
            const taza_usd = JSON.parse(res)
            return this.setState({ loadingTaza: false, taza_usd })
        })
        .catch(error => {
            this.setState({ loadingTaza: false })
        })
    }

    getTaza(){
        return fetch('https://us-east-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/webdym-xbzid/service/webdym/incoming_webhook/getTaza')
        .then(res => res.json())
        .then(res => {
            const taza = JSON.parse(res)
            return this.setState({ loadingTaza: false, taza })
        })
        .catch(error => {
            this.setState({ loadingTaza: false })
        })
    }

    showByTipoCuenta(tipo){
        const { is_pagomovil } = this.state

        if(is_pagomovil === 'si') return 'Pago móvil'


        return tipo
    }

    showResumen(){
        const { cambiar, taza, nombre_titular, is_pagomovil, apellido_titular, tipo_cedula, cedula_titular, banco, tipo_cuenta, numcuenta } = this.state

        return <div>

            <h2>Resumen</h2>

            <Row>
                <Col xs={7} className="text-left">
                    <h4><b>Titular</b></h4>
                </Col>
                <Col xs={5} style={{ textAlign: 'right' }}>
                    <Button variant="outline-primary" style={{ fontSize: 10 }} size="sm" onClick={()=>this.setState({ tipovisualizacion: 'titular' })} >EDITAR</Button>
                </Col>
                <Col xs={12}>
                    <div className="contenedor mt-2 mb-4">
                        <Row>
                            <Col xs={6}>Nombres</Col>
                            <Col xs={6} style={{ textAlign: 'right' }} >{nombre_titular}</Col>
                            <Col xs={6}>Apellidos</Col>
                            <Col xs={6} style={{ textAlign: 'right' }} >{apellido_titular}</Col>
                            <Col xs={6}>Cédula de identidad</Col>
                            <Col xs={6} style={{ textAlign: 'right' }} >{tipo_cedula} {cedula_titular}</Col>
                        </Row>
                    </div>
                </Col>
            </Row>


            <Row>
                <Col xs={7} className="text-left">
                    <h4><b>Datos bancarios</b></h4>
                </Col>
                <Col xs={5} style={{ textAlign: 'right' }}>
                    <Button variant="outline-primary" style={{ fontSize: 10 }} size="sm" onClick={()=>this.setState({ tipovisualizacion: 'datosbancarios' })}>EDITAR</Button>
                </Col>
                <Col xs={12}>
                    <div className="contenedor mt-2">
                        <Row>
                            <Col xs={6}>Banco</Col>
                            <Col xs={6} style={{ textAlign: 'right' }} >{banco}</Col>
                            <Col xs={6}>Tipo de cuenta</Col>
                            <Col xs={6} style={{ textAlign: 'right' }} >{this.showByTipoCuenta(tipo_cuenta)}</Col>
                            <Col xs={6}>{ is_pagomovil === 'si' ? 'Número de teléfono' : 'Cuenta'}</Col>
                            <Col xs={6} style={{ textAlign: 'right' }} >{numcuenta}</Col>
                        </Row>
                        
                    </div>
                </Col>
            </Row>


            <Row>
                <Col xs={12} className="mt-3">
                    <h4><b>Detalles</b></h4>
                </Col>
                <Col xs={12} className="mb-4">
                    <div className="mt-2 mb-4">
                        <Row>
                            <Col xs={6} >Taza de cambio</Col>
                            <Col xs={6} style={{ textAlign: 'right' }}>{taza.ammount}</Col>
                        </Row>
                    </div>
                </Col>
            </Row>


            <div className="form-group mb-5">
            <div style={{ marginBottom: -83 }}>
                <div style={{ textAlign: 'right' }}>
                <img alt="cl" src="cl.png" style={{ width: 30, display: 'inline-block',verticalAlign: 'middle', margin: 0 }} />
                <h3 style={{ display: 'inline-block',verticalAlign: 'middle', margin: 0, padding: 10 }}>CLP</h3>
                </div>
            </div>
        <label className="form-control-label mb-2">Cambiar</label>
        <input className="form-control customformcontrol" name="cambiar" value={cambiar} onChange={this.handleChangeMonto} />
        </div>

        <div className="form-group mb-3">
        <div style={{ marginBottom: -83 }}>
                <div style={{ textAlign: 'right' }}>
                <img alt="ve" src="ve.png" style={{ width: 30, display: 'inline-block',verticalAlign: 'middle', margin: 0 }} />
                <h3 style={{ display: 'inline-block',verticalAlign: 'middle', margin: 0, padding: 10 }}>VES</h3>
                </div>
            </div>
        <label className="form-control-label mb-2">Recibes</label>
        <input className="form-control customformcontrol" value={(cambiar*taza.ammount).toFixed(taza.decimals)} />
        </div>

        <div className="d-grid gap-2 mt-3">
         <Button variant="success" size="lg" onClick={()=>this.confirmar()} >CONFIRMAR</Button>
        </div>
        

        </div>
    }

    confirmar(){
        const { cambiar } = this.state
        if(!cambiar) return toast.error('El valor a cambiar no es válido')

        return this.setState({ tipovisualizacion: 'confirmacion' })
    }

    async enviarSolicitud(){
        const validar = await this.getTazaV2()
        if(!validar) return this.setState({ tipovisualizacion: 'resumen' })
        await this.confirmarSolicitud()
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    confirmarSolicitud(){
        const { cambiar, taza, imagen, user, nombre_titular, is_pagomovil, apellido_titular, tipo_cedula, cedula_titular, banco, tipo_cuenta, numcuenta, apodo } = this.state

        const enviar = {
            nombre_titular,
            apellido_titular,
            tipo_cedula,
            cedula_titular,
            banco,
            tipo_cuenta,
            numcuenta,
            cambiar,
            taza,
            date: new Date(),
            fecha: this.formatDate(new Date()),
            imagen,
            email: user.email,
            login: user.login,
            apodo,
            is_pagomovil,
            status: "0"
        }

        this.setState({ loadingVerificacion: true })
        return fetch(`https://us-east-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/webdym-xbzid/service/webdym/incoming_webhook/enviarSolicitud`,{
            method: 'POST',
            body: JSON.stringify(enviar),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            this.getCuentas(user.email)
            return this.setState({ tipovisualizacion: 'terminado', loadingVerificacion: false })
        })
        .catch(error => {
            this.setState({ enviando: false, loadingVerificacion: false })
            return toast.error('Hubo un problema y no pudimos realizar esta operación, intenta de nuevo')
        })

    }

    volverHome(){
        return this.setState({ 
        tipovisualizacion: 'taza', 
        nombre_titular: '',
        apellido_titular: '',
        cedula_titular: '',
        cambiar: '',
        apodo: '',
        numcuenta: '',
        banco: '',
        tipo_cuenta: '',
        datos_bancarios_permitir: false
        })
    }

    terminado(){

        return <div className="text-center" style={{ padding: 40 }}>
            <img alt="listo" style={{ width: 80 }} src="check.gif" />
            <h3>Enviado exitosamente</h3>
            <Button variant="outline-primary" size={'sm'} onClick={()=>this.volverHome()} >VOLVER</Button>
        </div>
    }

    confirmacionFinal(){
        const { imagenlista, loadingVerificacion, loadingImagen, imagen } = this.state

        if(loadingVerificacion || loadingImagen) return <Spinner animation="border" />

        if(!imagenlista || !imagen) return <Button variant="secondary" >SUBE TU IMAGEN PARA CONTINUAR...</Button>

        return <Button variant="success" onClick={()=>this.enviarSolicitud()}>CONFIRMAR</Button>
        
    }

    showConfirmacion(){

        return <div className="mt-2">

<h2 className="mb-2">A continuación adjunta el comprobante de tu transferencia</h2>
<label className="form-control-label">Carga tu imagen</label>
<input type="file" className="form-control mb-3" onChange={this.handleChangeFile} />
<div className="d-grid gap-2 mt-3 mb-3">
{ this.confirmacionFinal() }
</div>

            <img alt="banco itau" style={{ width: 150 }} src="itau.png" />
            <p className="mb-0" ><b>Dymsolutions spa</b></p>
            <p className="mb-0" >Rut</p>
            <h3 className="mb-0" >77383158-0</h3>
            <p className="mb-0" >Cta. Corriente</p>
            <h3 className="mb-0" >222235244</h3>
            <p className="mb-0" >dymsolutions7@gmail.com</p>

            <hr />

            <img alt="banco estado" style={{ width: 150 }} src="bancoestado.png" />
            <p className="mb-0" ><b>Dymsolutions spa</b></p>
            <p className="mb-0" >Rut</p>
            <h3 className="mb-0" >77383158-0</h3>
            <p className="mb-0" >Cta. Vista | Chequera Electrónica</p>
            <h3 className="mb-0" >33770378481</h3>
            <p className="mb-0" >dymsolutions7@gmail.com</p>

        </div>
        
    }

    showDatosBancariosForm(){
        const { datos_bancarios_permitir, reglasbanco, is_pagomovil, numcuenta, cambiar, taza, banco, tipo_cuenta } = this.state

        return <div>
            
        <h2 className="mb-4 mt-3">¿Donde debemos depositar los <b > {Intl.NumberFormat("es-ES").format(cambiar * taza.ammount )} VES?</b></h2>

        { /* <div className="form-group mb-3">
        <label className="form-control-label mb-2">Banco</label>
        <Select options={options}
        placeholder="Seleccione un banco"
        onChange={this.handleChangeSelectBanco}
        />
        </div> */}

        {this.showCuentasIfCuentas()}

        <div className="form-group mb-3">
        <label className="form-control-label mb-2">Banco</label>
        <select className="form-control customformcontrol" name="banco" value={banco} onChange={this.handleChangeState} >
                <option value="">Seleccione</option>
                {reglasbanco.map((banco,ibanc) => {
                    return <option key={`i${ibanc}`} value={banco.slug}>{banco.nombre}</option>
                })}
        </select>
        </div>

        <div className="form-group mb-3">
        <label className="form-control-label mb-2">¿Es pago móvil?</label>
        <select className="form-control customformcontrol" name="is_pagomovil" value={is_pagomovil} onChange={this.handleChangeState} >
        <option value="si">Si</option>
        <option value="no">No</option>                
        </select>
        </div>

        { /* <div className="form-group mb-3">
        <label className="form-control-label mb-2">Tipo de cuenta</label>
        <Select options={tipos_cuenta}
        placeholder="Seleccione un banco"
        onChange={this.handleChangeSelectTipoCuenta}
        />
    </div> */}

        {
            is_pagomovil === 'no' ? <div className="form-group mb-3">
            <label className="form-control-label mb-2">Tipo de cuenta</label>
            <select className="form-control customformcontrol" name="tipo_cuenta" value={tipo_cuenta} onChange={this.handleChangeState} >
                    <option value="">Seleccione</option>
                    <option value="Ahorro">Ahorro</option>
                    <option value="Corriente">Corriente</option>
            </select>
            </div> : false
        }
        

        <div className="form-group mb-3">
        <label className="form-control-label mb-2"> { is_pagomovil === 'si' ? 'Número de teléfono · Ejemplo (04241234567)' : 'Número de cuenta'} </label>
        <input className="form-control customformcontrol" name="numcuenta" value={numcuenta} onChange={this.handleChangeState} />
        </div>

        <div className="d-grid gap-2 mt-3">
        { datos_bancarios_permitir === true ? <Button variant="primary" size="lg" onClick={()=>this.Next(3)}>GUARDAR CAMBIOS</Button> : <Button variant="secondary" size="lg" disabled >GUARDAR CAMBIOS</Button> } 
        </div>

    </div>

    }

    showTazaForm(){
        const { taza, cambiar, loadingTaza } = this.state

        if(loadingTaza) return <Spinner animation="border" />


        if(!taza) return <h4>Taza no disponible</h4>
        return <div>
            
        <h5 className="">La tasa de cambio para hoy es </h5>
        <h1 className="mb-5" style={{ color:'#fbd144' }}>1 <b>{taza.from}</b> = {1*taza.ammount} <b >{taza.to}</b></h1>

        <div className="form-group mb-5">
            <div style={{ marginBottom: -83 }}>
                <div style={{ textAlign: 'right' }}>
                <img alt="cl" src="cl.png" style={{ width: 30, display: 'inline-block',verticalAlign: 'middle', margin: 0 }} />
                <h3 style={{ display: 'inline-block',verticalAlign: 'middle', margin: 0, padding: 10 }}>CLP</h3>
                </div>
            </div>
        <label className="form-control-label mb-2">Cambiar</label>
        <input className="form-control customformcontrol" name="cambiar" value={cambiar} onChange={this.handleChangeMonto} />
        </div>

        <div className="form-group mb-5">
        <div style={{ marginBottom: -83 }}>
                <div style={{ textAlign: 'right' }}>
                <img alt="ve" src="ve.png" style={{ width: 30, display: 'inline-block',verticalAlign: 'middle', margin: 0 }} />
                <h3 style={{ display: 'inline-block',verticalAlign: 'middle', margin: 0, padding: 10 }}>VES</h3>
                </div>
            </div>
        <label className="form-control-label mb-2">Recibes</label>
        <input className="form-control customformcontrol" value={(cambiar*taza.ammount).toFixed(taza.decimals)} />
        </div>

        {this.ifUSDTazaShowConversion()}

        <div className="d-grid gap-2 mt-3">
        <Button variant="primary" size="lg" onClick={()=>this.Next(1)}>SIGUIENTE</Button>
        </div>

    </div>

    }

    ifUSDTazaShowConversion(){
        const { taza_usd, taza, monto_usd  } = this.state
        if(!taza_usd || !taza) return false

        return <div className="form-group mb-3">
        <div style={{ marginBottom: -83 }}>
                <div style={{ textAlign: 'right' }}>
                <img alt="usa" src="usa.png" style={{ width: 30, display: 'inline-block',verticalAlign: 'middle', margin: 0 }} />
                <h3 style={{ display: 'inline-block',verticalAlign: 'middle', margin: 0, padding: 10 }}>USD</h3>
                </div>
        </div>
        <label className="form-control-label mb-2">Cambio DolarMonitor: <b style={{ color:'#fbd144' }}>1 USD = {taza_usd.ammount} VES</b></label>
        <input className="form-control customformcontrol" value={(monto_usd).toFixed(0)} onChange={this.handleChangeMontoV2} />
        </div>

    }

    Next(num){
        const { cambiar, nombre_titular, banco, minimo_cambiar, maximo_cambiar, numcuenta, tipo_cuenta, apellido_titular, cedula_titular, apodo, tipo_cedula } = this.state
        if(num === 1){

            if(!cambiar) return toast.error('Ingresa el monto a cambiar')

            if(parseInt(cambiar) < minimo_cambiar) return toast.error(`El monto mínimo a cambiar es $${Intl.NumberFormat("es-ES").format(minimo_cambiar)}`)

            if(parseInt(cambiar) > maximo_cambiar) return toast.error(`El monto máximo a cambiar es $${Intl.NumberFormat("es-ES").format(maximo_cambiar)}`)

            return this.setState({ tipovisualizacion: 'titular' })

        } else if(num === 2){

            if(!nombre_titular || !apellido_titular || !cedula_titular || !apodo  || !tipo_cedula) return toast.error('Todos los campos son requeridos')

            return this.setState({ tipovisualizacion: 'datosbancarios' })
        } else if(num === 3){

            if( !numcuenta || !banco || !tipo_cuenta ) return toast.error('Todos los campos son requeridos')

            return this.setState({ tipovisualizacion: 'resumen' })
        }


    }

    showCuentasIfCuentas(){
        const { cuentas } = this.state

        if(cuentas.length < 1) return false


        return <div>

<FloatingLabel controlId="floatingSelect" label="Selecciona una de tus cuentas" className="mb-3">
  <Form.Select aria-label="Floating label select example" onChange={this.handleChangeCuenta}>
    <option>Seleccione</option>
    {
        cuentas.map(cuenta => {
            return <option value={cuenta._id['$oid']} >{ cuenta.apodo ? cuenta.apodo : `${cuenta.nombre_titular} ${cuenta.apellido_titular} ${cuenta.banco}`}</option>
        })
    }
  </Form.Select>
</FloatingLabel>

        </div>



    }

    showTitularForm(){
        const { nombre_titular, apellido_titular, cedula_titular, apodo, tipo_cedula } = this.state

        return <div>
            
        <h2 className="mb-4 mt-3">¿Quien es el titular de la cuenta en Venezuela?</h2>

        {this.showCuentasIfCuentas()}

        <div className="form-group mb-3">
        <label className="form-control-label mb-2">Nombres</label>
        <input className="form-control customformcontrol" value={nombre_titular} name="nombre_titular" onChange={this.handleChangeState} />
        </div>

        <div className="form-group mb-3">
        <label className="form-control-label mb-2">Apellidos</label>
        <input className="form-control customformcontrol"  value={apellido_titular} name="apellido_titular" onChange={this.handleChangeState} />
        </div>

        <div className="form-group mb-3">
        <label className="form-control-label mb-2">Cédula de identidad</label>
        <Row>
            <Col xs={3}>
            <Form.Select aria-label="Default select example" style={{ height: 54 }} name="tipo_cedula" value={tipo_cedula} onChange={this.handleChangeState}>
            <option value="V">V</option>
            <option value="E">E</option>
            </Form.Select>
            </Col>
            <Col xs={9}>
            <input className="form-control customformcontrol" value={cedula_titular} name="cedula_titular" maxLength="8" onChange={this.handleChangeState} />
            </Col>
        </Row>
        </div>

        <div className="form-group mb-3">
        <label className="form-control-label mb-2">Apodo</label>
        <input className="form-control customformcontrol" placeholder="Mi cuenta personal" value={apodo} name="apodo" onChange={this.handleChangeState} />
        </div>

        <div className="d-grid gap-2 mt-3">
        <Button variant="primary" size="lg" onClick={()=>this.Next(2)}>GUARDAR CAMBIOS</Button>
        </div>

    </div>

    }

    showWs(href){

        return <div className="whatsapp">
            <a target="_blank" rel="noreferrer" href={href} >
                <img src="ws.png" alt="whatsapp" />
            </a>
        </div>
    }

    render(){
        const { ws_number } = this.state
        return <div>
            {this.showWs(ws_number)}
            {this.showAcciones()}
        {this.showByTipoVisualizacion()}
        </div>
    }
}

export default Inicio